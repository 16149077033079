import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {CompaniesInterface, CompanyInterface} from "../../interfaces/companies-interface";
import {CompanyPlacesInterface} from "../../interfaces/company-place-interface";

@Injectable({
    providedIn: 'root'
})
export class CompaniesHttpService {
    apiUrl = environment.dataApi + 'companies';
    dispatchUrl = environment.dispatchApi + 'companies';

    constructor(private httpClient: HttpClient) {
    }

    async getItems() {
        const url = this.apiUrl;

        return await this.httpClient
            .get<CompaniesInterface>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });
    }

    async getItem(companyId) {
        const url = this.apiUrl + '/' + companyId;

        return await this.httpClient
            .get<CompanyInterface>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    async postItem(body: CompanyInterface) {
        const url = this.apiUrl;

        return await this.httpClient
            .post<CompanyInterface>(url, body)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    async putItem(companyId, body: CompanyInterface) {
        const url = this.apiUrl + '/' + companyId;

        return await this.httpClient
            .put<CompanyInterface>(url, body)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    getViews(company: CompanyInterface) {
        const url = this.apiUrl + '/' + company.id + '/trips/viewslist';

        return this.httpClient.get<any>(url);
    }

    getViewData(company: CompanyInterface, viewName: string) {
        const url = this.apiUrl + '/' + company.id + '/trips/exportviewdata?viewname=' + viewName;

        return this.httpClient.get<any>(url);
    }

    getLastThreeMonthCourses(companyId, query = '') {
        const url = this.dispatchUrl + '/' + companyId + '/courses/last-three-month' + query;

        return this.httpClient.get<any>(url);
    }

}
