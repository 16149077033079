import { CompanyNotifsInterface } from '../../interfaces/company-notifs-interface';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CompanyInterface } from 'src/app/interfaces/companies-interface';
@Injectable({
  providedIn: 'root'
})
export class NotificationPostFolderHttpService {
  apiUrl = environment.dataApiDriver;

  constructor(private httpClient: HttpClient) {
  }

    async getItemsPostFolder(userId, company: CompanyInterface) {
      const url = this.apiUrl + 'users/' + userId + '/notification/' + company.id;

      return await this.httpClient
          .get<CompanyNotifsInterface>(url)
          .toPromise()
          .then(data => {
              return {status: 200, data};
          })
          .catch(error => {
              return error;
          });
  }

    async upddateNotifReadAt(TabNotifId:Array<any>, userId) {
      const url = this.apiUrl + 'users/' + userId + '/update-notif-readAt';

      return await this.httpClient
          .put<Array<any>>(url, TabNotifId)
          .toPromise()
          .then(data => {
              return {status: 200, data};
          })
          .catch(error => {
              return error;
          });

  }
}
