import {HttpListMetaInterface} from './http-list-meta-interface';

export interface CompanyTripsInterface {
    data: Array<TripInterface>;
    meta: HttpListMetaInterface;
}

export interface TripInterface {
    animals: boolean;
    cancel_code: string;
    cancel_trying?: number;
    client_id: number;
    comments: string;
    commercial_consent: number;
    company_id: string;
    course_state?: number;
    course_type: string;
    ct_code?: string;
    date_time: string;
    dest_address?: Tripaddress;
    direction: number;
    dispatcher_reference?: string;
    email: string;
    first_name: string;
    id?: string;
    internal_reference?: string;
    last_name: string;
    lasttracking: any;
    mail_recall: number;
    meta?: string;
    passengers: number;
    payment_type?: string;
    phone: string;
    ref_type_course: string;
    relay_id: string;
    relay_reference?: string;
    send_notif60: number;
    start_address: Tripaddress;
    tracking_meta?: any;
    trip_client_notice?: number;
    using_consent: number;
    vehicle_type: vehicule_types;
}

export interface Tripaddress {
    name: string;
    city: string;
    address: string;
    number: string;
    longitude: number;
    latitude: number;
}

export enum vehicule_types {
    'berline' = 1,
    'break' = 2,
    'van' = 3
}