import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastService } from '../shared/toast.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserHttpServiceService {
    apiUrl = environment.authApi + 'auth';

    constructor(private httpClient: HttpClient) {
    }

    /**
     * Login user
     * @param credentials Credentials
     */
    async login(credentials: any) {
        const url = this.apiUrl + '/login';

        return await this.httpClient
            .post<any>(url, credentials)
            .toPromise()
            .then(data => {
                return { status: 200, data };
            })
            .catch(error => {
                return error;
            });
    }

    async logout(token: string) {
        const url = this.apiUrl + '/logout';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            })
        };
        return await this.httpClient
            .post<any>(url, null, httpOptions)
            .toPromise()
            .then(data => {
                return { status: 200, data };
            })
            .catch(error => {
                return error;
            });
    }

    async getMe(token: string) {
        const url = this.apiUrl + '/me';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            })
        };
        return await this.httpClient
            .post<any>(url, null, httpOptions)
            .toPromise()
            .then(data => {
                return { status: 200, data };
            })
            .catch(error => {
                return error;
            });
    }

    async getUsers() {
        return await this.httpClient
            .get<any>(`${environment.dispatchApi}users`)
            .toPromise();
    }
}
