import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CompanyInterface } from '../../interfaces/companies-interface';
import { CompanyTripsInterface, TripInterface } from '../../interfaces/company-trips-interface';

@Injectable({
    providedIn: 'root'
})
export class CompanyTripHttpService {
    apiUrl = environment.dataApi + 'companies/';

    constructor(private httpClient: HttpClient) {
    }

    async getItems(company: CompanyInterface, query: string = '') {
        const url = `${this.apiUrl}${company.id}/trips${query}`;

        return await this.httpClient
            .get<CompanyTripsInterface>(url)
            .toPromise()
            .then(data => {
                return { status: 200, data };
            })
            .catch(error => {
                return error;
            });
    }

    async getItem(company: CompanyInterface, tripId) {
        const url = this.apiUrl + company.id + '/trips/' + tripId;

        return await this.httpClient
            .get<TripInterface>(url)
            .toPromise()
            .then(data => {
                return { status: 200, data };
            })
            .catch(error => {
                return error;
            });

    }

    async postItem(company: CompanyInterface, body: TripInterface) {
        const url = this.apiUrl + company.id + '/trips';

        return await this.httpClient
            .post<TripInterface>(url, body)
            .toPromise()
            .then(data => {
                return { status: 200, data };
            })
            .catch(error => {
                return error;
            });

    }
    async putItem(company: CompanyInterface, tripId: string, body: TripInterface) {
        const url = this.apiUrl + company.id + '/trips/' + tripId;

        return await this.httpClient
            .put<TripInterface>(url, body)
            .toPromise()
            .then(data => {
                return { status: 200, data };
            })
            .catch(error => {
                return error;
            });

    }

    async deleteItem(company: CompanyInterface, tripId) {
        const url = this.apiUrl + company.id + '/trips/' + tripId;

        return await this.httpClient
            .delete<any>(url)
            .toPromise()
            .then(data => {
                return { status: 200, data };
            })
            .catch(error => {
                return error;
            });

    }
}
