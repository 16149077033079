import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './dataobject/user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private userService: UserService) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const a = this.userService.isLoggedIn();
        const roles = route.data.roles as Array<string>;

        console.log({ roles });

        let hasRole = false;
        if (!roles) {
            hasRole = true;
        } else {
            roles.forEach(value => {
                if (this.userService.hasRole(value)) {
                    hasRole = true;
                }
            });
        }

        console.log(route.data);
        if (a && hasRole) {
            return true;
        } else {
            this.router.navigate(['login']);
            return false;
        }
    }

}
