import {NotificationPostFolderHttpService} from './../api/notification-post-folder-http.service';
import {DriverData} from './../../interfaces/user-interface';
import {Injectable} from '@angular/core';
import {
    CompaniesInterface,
    CompanyInterface,
    CompanyDriverInterface,
    DispatcherparamsInterface,
    CompanyDriversInterface,
    CompanyDriverFolderInterface,
    ADSDocumentInterface,
    DriverLicenseInterface,
    EntrepriseDocumentInterface,
    InsuranceDocumentInterface,
    ProfessionalLicenseDocumentInterface,
} from '../../interfaces/companies-interface';
import {CompaniesHttpService} from '../api/companies-http.service';
import {CompanyVehicleHttpService} from '../api/company-vehicle-http.service';

import {CompanyUserHttpService} from '../api/company-user-http.service';
import {CompanyDriverHttpService} from '../api/company-driver-http.service';
import {CompanyUsersInterface} from '../../interfaces/company-users-interface';
import {Userdata} from '../../interfaces/user-interface';
import {CompanyRelayHttpService} from '../api/company-relay-http.service';
import {
    CompanyRelaysInterface,
    RelayInterface,
} from '../../interfaces/company-relays-interface';
import {
    CompanyPlaceInterface,
    CompanyPlacesInterface,
} from '../../interfaces/company-place-interface';
import {CompanyPlaceHttpService} from '../api/company-place-http.service';
import {CompanyDriverFolderHttpService} from '../api/company-driver-folder-http.service';
import {
    CompanyTripsInterface,
    Tripaddress,
    TripInterface,
    vehicule_types,
} from './../../interfaces/company-trips-interface';
import {CompanyTripHttpService} from './../api/company-trip-http.service';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {ReturnStatement} from '@angular/compiler';
import {async} from 'rxjs/internal/scheduler/async';
import {
    CompanyVehiclesInterface,
    VehicleInterface,
} from 'src/app/interfaces/company-vehicles-interface';
import {NotificationData} from 'src/app/interfaces/notification-interface';
import {CompanyNotifsInterface} from 'src/app/interfaces/company-notifs-interface';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    companies: CompaniesInterface;
    company: CompanyInterface;
    companyVehicles: CompanyVehiclesInterface;
    companyVehicle: VehicleInterface;
    companyUsers: CompanyUsersInterface;
    companyUser: Userdata;
    companyRelays: CompanyRelaysInterface;
    companyRelay: RelayInterface;
    companyPlaces: CompanyPlacesInterface;
    companyPlace: CompanyPlaceInterface;
    companyTrips: CompanyTripsInterface;
    companyDriver: CompanyDriverInterface;
    companyDrivers: CompanyDriversInterface;
    companyDriversFolder: CompanyDriverFolderInterface;
    localDocuments: any[];
    companyUserDriver: DriverData;
    companyUserDrivers: CompanyUsersInterface;
    notificationPostFolders: CompanyNotifsInterface;
    numbersOfNotifsPostFolder = 0;
    TabNotifications: Array<any> = [];

    constructor(
        public companiesHttpService: CompaniesHttpService,
        private companyVehicleHttpService: CompanyVehicleHttpService,
        private companyUserHttpService: CompanyUserHttpService,
        private companyRelayHttpService: CompanyRelayHttpService,
        private companyPlaceHttpService: CompanyPlaceHttpService,
        private companyTripHttpService: CompanyTripHttpService,
        private companyDriverHttpService: CompanyDriverHttpService,
        private companyDriverFolderHttpService: CompanyDriverFolderHttpService,
        private notificationPostFolderHttpService: NotificationPostFolderHttpService
    ) {
    }

    /**************************************************************************/
    // NOTIFICATIONS
    /**************************************************************************/

    async getNotificationsPostFolder(userId) {
        this.numbersOfNotifsPostFolder = 0;
        this.TabNotifications = [];
        this.notificationPostFolderHttpService.getItemsPostFolder(userId, this.company).then((requestResult) => {
            if (requestResult.status === 200) {
                this.TabNotifications = requestResult.data;
                this.notificationPostFolders = requestResult.data;
                this.numbersOfNotifsPostFolder = requestResult.data.length;
                console.log('on service', this.notificationPostFolders);
                console.log('on length', this.numbersOfNotifsPostFolder);
                return true;
            } else {
                return false;
            }
        });
    }

    async updateNotifications(TabNotifId: Array<any>, userId) {
        const updateResult = await this.notificationPostFolderHttpService.upddateNotifReadAt(
            TabNotifId, userId
        );
        if (updateResult.status === 200) {
            this.getNotificationsPostFolder(userId); // Mise à jour de la liste
            return true;
        } else {
            return false;
        }
    }

    /********************************************************************************************/
    // END NOTIFICATIONS
    /*******************************************************************************************/

    async getItems() {
        const requestResult = await this.companiesHttpService.getItems();

        if (requestResult.status === 200) {
            this.companies = requestResult.data;
            console.log(this.companies);
            return true;
        } else {
            return false;
        }
    }

    async changeCompany(id: string, userId, reason) {
        const infos = await this.companyDriverFolderHttpService.changeCompany(
            id, userId
        );

        if (infos.status === 200) {
            // this.localDocuments = infos.data.folder;
            return true;
        } else {
            return false;
        }
    }

    async deleteFolder(id) {
        const infos = await this.companyDriverFolderHttpService.deleteFolder(
            id
        );

        if (infos.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    async getRequiredDocuments(companyId: string) {
        const infos = await this.companyDriverFolderHttpService.getRequiredDocuments(
            companyId
        );

        if (infos.status === 200) {
            this.localDocuments = infos.data.folder;
            return true;
        } else {
            return false;
        }
    }

    async getDriverFolder(id: string) {
        const folder = await this.companyDriverFolderHttpService.getFolder(id);
        return folder;
    }

    async getDriverFolderById(id: string) {
        const infos = await this.companyDriverFolderHttpService.getFolder(id);

        if (infos) {
            this.companyDriversFolder = infos;
            const ads = await this.companyDriverFolderHttpService.getADS(id);

            if (ads) {
                this.companyDriversFolder.ads = ads;
            }

            const dl =
                await this.companyDriverFolderHttpService.getDriverLicenseDocument(id);
            if (dl) {
                this.companyDriversFolder.driver_license = dl;
            }

            const ins =
                await this.companyDriverFolderHttpService.getInsuranceDocument(id);
            if (ins) {
                this.companyDriversFolder.insurance = ins;
            }

            const el =
                await this.companyDriverFolderHttpService.getEntrepriseDocument(id);
            if (el) {
                this.companyDriversFolder.entreprise = el;
            }

            const lm =
                await this.companyDriverFolderHttpService.getLicenseMarchandise(id);
            if (lm) {
                this.companyDriversFolder.license_marchandise = lm;
            }

            const fc = await this.companyDriverFolderHttpService.getFormationContinue(
                id
            );
            if (fc) {
                this.companyDriversFolder.formation_continue = fc;
            }

            const rcp = await this.companyDriverFolderHttpService.getRcPro(id);
            if (rcp) {
                this.companyDriversFolder.rc_pro = rcp;
            }

            const vm = await this.companyDriverFolderHttpService.getVisiteMedical(id);
            if (vm) {
                this.companyDriversFolder.visite_medicale = vm;
            }

            const pl =
                await this.companyDriverFolderHttpService.getProfessionalLicenseDocument(
                    id
                );
            if (pl) {
                this.companyDriversFolder.professional_license = pl;
            }

            //   this.hasAccessForMauruade = this.companyDriversFolder.state == 4 ? true : false;

            console.log(this.companyDriversFolder);
            return await true;
        } else {
            return await false;
        }
    }

    async getDriverItems() {
        this.companyDriverHttpService
            .getItems(this.company)
            .then((requestResult) => {
                if (requestResult.status === 200) {
                    this.companyDrivers = requestResult;
                    return true;
                } else {
                    return false;
                }
            });
    }

    async getItem(companyId: string) {
        this.resetCompanyData();
        if (companyId !== '0') {
            const requestResult = await this.companiesHttpService.getItem(companyId);

            if (requestResult.status === 200) {
                this.company = requestResult.data;
                return true;
            } else {
                return false;
            }
        } else {
            this.company = {
                id: '0',
                ip_whitelist: '',
                name: '',
                token_api: 'todo',
                address: '',
                zipcode: '',
                city: '',
                companyparams: {data: []},
            };
            return true;
        }
    }

    resetCompanyData() {
        this.companyRelays = null;
        this.companyPlaces = null;
        this.companyUsers = null;
        this.companyVehicles = null;
    }

    async saveCompanyAddress(values: Array<any>) {
        const company = {...this.company, ...values};

        if (company.id === '0') {
            const resultSave = await this.companiesHttpService.postItem(company);
            if (resultSave.status === 200) {
                this.company = resultSave.data;
                this.companies.data.push(this.company);
                return true;
            } else {
                return false;
            }
        } else {
            const resultSave = await this.companiesHttpService.putItem(
                company.id,
                company
            );
            if (resultSave.status === 200) {
                this.company = resultSave.data;
                const cIndex = this.companies.data.findIndex(
                    (obj) => obj.id === this.company.id
                );
                this.companies.data[cIndex] = this.company;
                return true;
            } else {
                return false;
            }
        }
    }

    async saveCompanyDispatcher(dispatcher) {
        const cDispatcher = this.getDispatcher(this.company);
        cDispatcher.config = dispatcher;
        if (cDispatcher.id) {
            const cParamIndex = this.company.companyparams.data.findIndex(
                (obj) => (obj.id = cDispatcher.id)
            );
            this.company.companyparams.data[cParamIndex] = cDispatcher;
        } else {
            this.company.companyparams.data.push(cDispatcher);
        }
        const resultSave = await this.companiesHttpService.putItem(
            this.company.id,
            this.company
        );
        if (resultSave.status === 200) {
            this.company = resultSave.data;
            const cIndex = this.companies.data.findIndex(
                (obj) => obj.id === this.company.id
            );
            this.companies.data[cIndex] = this.company;
            return true;
        } else {
            return false;
        }
    }

    getDispatcher(company: CompanyInterface): DispatcherparamsInterface {
        const newDispatcher: DispatcherparamsInterface = {
            param_type: 'DISPATCHER',
            config: {
                url: '',
                authorization: '',
                name: '',
                smtp_host: '',
                smtp_pass: '',
                smtp_port: '',
                smtp_user: '',
            },
        };
        if (company.companyparams.data.length === 0) {
            return newDispatcher;
        }

        const dispatcher = company.companyparams.data.find(
            (obj) => obj.param_type === 'DISPATCHER'
        );
        if (dispatcher) {
            return dispatcher;
        } else {
            return newDispatcher;
        }
    }

    /**************************************************************************
     * Vehicles
     */

    async getVehicles() {
        this.companyVehicleHttpService
            .getItems(this.company)
            .then((requestResult) => {
                if (requestResult.status === 200) {
                    this.companyVehicles = requestResult.data;
                    return true;
                } else {
                    return false;
                }
            });
    }

    async getVehicle(vehicleId) {
        if (vehicleId !== '0') {
            return await this.companyVehicleHttpService
                .getItem(this.company, vehicleId)
                .then((requestResult) => {
                    if (requestResult.status === 200) {
                        this.companyVehicle = requestResult.data;
                        return true;
                    } else {
                        return false;
                    }
                });
        } else {
            this.companyVehicle = {
                id: '0',
                code: '',
                color: '',
                description: '',
                make: '',
                model: '',
            } as any;
            return true;
        }
    }

    async saveVehicle(data) {
        this.companyVehicle = {...this.companyVehicle, ...data};
        if (this.companyVehicle.id !== '0') {
            const putResult = await this.companyVehicleHttpService.putItem(
                this.company,
                this.companyVehicle.id,
                this.companyVehicle
            );
            if (!putResult) {
                return false;
            } else {
                const index = this.companyVehicles.data.findIndex(
                    (obj) => obj.id === putResult.data.id
                );
                this.companyVehicles.data[index] = putResult.data;
                return true;
            }
        } else {
            const postResult = await this.companyVehicleHttpService.postItem(
                this.company,
                this.companyVehicle
            );
            if (!postResult) {
                return false;
            } else {
                this.companyVehicles.data.push(postResult.data);
                return true;
            }
        }
    }

    async deleteVehicle(id) {
        const deleteResult = await this.companyVehicleHttpService.deleteItem(
            this.company,
            id
        );

        if (!deleteResult) {
            return false;
        } else {
            const index = this.companyVehicles.data.findIndex((obj) => obj.id === id);
            this.companyVehicles.data.splice(index, 1);
            return true;
        }
    }

    /**************************************************************************
     * users
     */

    async getUsers() {
        this.companyUserHttpService.getItems(this.company).then((requestResult) => {
            if (requestResult.status === 200) {
                this.companyUsers = requestResult.data;
                return true;
            } else {
                return false;
            }
        });
    }

    async getUser(UserId) {
        if (UserId !== '0') {
            return await this.companyUserHttpService
                .getItem(this.company, UserId)
                .then((requestResult) => {
                    if (requestResult.status === 200) {
                        this.companyUser = requestResult.data;
                        return true;
                    } else {
                        return false;
                    }
                });
        } else {
            this.companyUser = {
                id: '0',
                name: '',
                email: '',
                roles: ['admin'],
                company_id: '',
                client_id: '',
                relay_id: '',
            };
            return true;
        }
    }

    async saveUser(data) {
        this.companyUser = {...this.companyUser, ...data};
        if (this.companyUser.id !== '0') {
            const putResult = await this.companyUserHttpService.putItem(
                this.company,
                this.companyUser.id,
                this.companyUser
            );
            if (!putResult) {
                return false;
            } else {
                const index = this.companyUsers.data.findIndex(
                    (obj) => obj.id === putResult.data.id
                );
                this.companyUsers.data[index] = putResult.data;
                return true;
            }
        } else {
            const postResult = await this.companyUserHttpService.postItem(
                this.company,
                this.companyUser
            );
            if (!postResult) {
                return false;
            } else {
                this.companyUsers.data.push(postResult.data);
                return true;
            }
        }
    }

    async deleteUser(id) {
        const deleteResult = await this.companyUserHttpService.deleteItem(
            this.company,
            id
        );

        if (!deleteResult) {
            return false;
        } else {
            const index = this.companyUsers.data.findIndex((obj) => obj.id === id);
            this.companyUsers.data.splice(index, 1);
            return true;
        }
    }

    /**************************************************************************
     * Drivers
     */
    async getDriver(DriverId) {
        if (DriverId !== '0') {
            return await this.companyUserHttpService
                .getDriverAccounItem(this.company, DriverId)
                .then((requestResult) => {
                    if (requestResult.status === 200) {
                        this.companyUserDriver = requestResult.data;
                        return true;
                    } else {
                        return false;
                    }
                });
        } else {
            this.companyUserDriver = {
                id: 0,
                name: '',
                email: '',
                password: '',
                company_id: '',
                client_id: '',
                email_verified_at: null,
                roles: ['user', 'driver'],
                person: null,

            };
            return true;
        }
    }

    async saveDriver(data) {
        this.companyUserDriver = {...data};
        if (this.companyUserDriver.id !== 0) {
            const putResult = await this.companyUserHttpService.putDriverAccountItem(
                this.company,
                this.companyUserDriver.id,
                this.companyUserDriver
            );
            if (!putResult) {
                return false;
            } else {
                return true;
            }
        } else {
            const postResult = await this.companyUserHttpService.postDriverAccountItem(
                this.companyUserDriver,
            );
            if (!postResult) {
                return false;
            } else {
                this.companyUsers.data.push(postResult.data);
                this.companyUserDriver = postResult.data;
                return true;
            }
        }
    }

    /**************************************************************************
     * End Drivers
     */

    /**************************************************************************
     * Relays
     */

    async getRelays() {
        this.companyRelayHttpService
            .getItems(this.company)
            .then((requestResult) => {
                if (requestResult.status === 200) {
                    this.companyRelays = requestResult.data;
                    return true;
                } else {
                    return false;
                }
            });
    }

    async getRelay(relayId) {
        if (relayId !== '0') {
            return await this.companyRelayHttpService
                .getItem(this.company, relayId)
                .then((requestResult) => {
                    if (requestResult.status === 200) {
                        this.companyRelay = requestResult.data;
                        return true;
                    } else {
                        return false;
                    }
                });
        } else {
            const place: CompanyPlaceInterface = {
                address: '',
                city: '',
                id: '',
                latitude: 0,
                longitude: 0,
                name: '',
                nfc_id: 'none',
                number: 0,
                published: 1,
                published_from: moment().format('YYYY-MM-DD'),
                published_to: moment().add(10, 'years').format('YYYY-MM-DD'),
                type: 'Relay',
            };
            this.companyRelay = {
                id: '0',
                customer: 0,
                customer_code: '',
                customer_id: '',
                place,
                published: 1,
                company_id: '',
                send_sms: false,
                relay_type: '',
                classification: '',
                forfait: false,
                nfc_id: '',
                default_comment: ''
            };
            return true;
        }
    }

    async saveRelay(data) {
        const place: any = {...this.companyRelay.place, ...data.place};
        this.companyRelay = {...this.companyRelay, ...data};
        this.companyRelay.place = place;

        if (this.companyRelay.id !== '0') {
            const putResult = await this.companyRelayHttpService.putItem(
                this.company,
                this.companyRelay.id,
                this.companyRelay
            );
            if (!putResult) {
                return false;
            } else {
                const index = this.companyRelays.data.findIndex(
                    (obj) => obj.id === putResult.data.id
                );
                this.companyRelays.data[index] = putResult.data;
                return true;
            }
        } else {
            const postResult = await this.companyRelayHttpService.postItem(
                this.company,
                this.companyRelay
            );
            if (!postResult.status && postResult.status !== 200) {
                return false;
            } else {
                this.companyRelays.data.push(postResult.data);
                return true;
            }
        }
    }

    async deleteRelay(id) {
        const deleteResult = await this.companyRelayHttpService.deleteItem(
            this.company,
            id
        );

        if (!deleteResult) {
            return false;
        } else {
            const index = this.companyRelays.data.findIndex((obj) => obj.id === id);
            this.companyRelays.data.splice(index, 1);
            return true;
        }
    }

    /**************************************************************************
     * Places
     */

    async getPlaces() {
        this.companyPlaceHttpService
            .getItems(this.company)
            .then((requestResult) => {
                if (requestResult.status === 200) {
                    this.companyPlaces = requestResult.data;
                    return true;
                } else {
                    return false;
                }
            });
    }

    async getPlace(placeId) {
        if (placeId !== '0') {
            return await this.companyPlaceHttpService
                .getItem(this.company, placeId)
                .then((requestResult) => {
                    if (requestResult.status === 200) {
                        this.companyPlace = requestResult.data;
                        return true;
                    } else {
                        return false;
                    }
                });
        } else {
            this.companyPlace = {
                address: '',
                city: '',
                id: '0',
                latitude: 0,
                longitude: 0,
                name: '',
                nfc_id: '',
                number: 0,
                published: 1,
                published_from: '2020-01-01T00:00:00.000000Z',
                published_to: '2100-12-31T00:00:00.000000Z',
                type: 'Relay',
            };
            return true;
        }
    }

    async savePlace(data) {
        this.companyPlace = {...this.companyPlace, ...data};
        if (this.companyPlace.id !== '0') {
            const putResult = await this.companyPlaceHttpService.putItem(
                this.company,
                this.companyPlace.id,
                this.companyPlace
            );
            if (!putResult) {
                return false;
            } else {
                const index = this.companyPlaces.data.findIndex(
                    (obj) => obj.id === putResult.data.id
                );
                this.companyPlaces.data[index] = putResult.data;
                return true;
            }
        } else {
            const postResult = await this.companyPlaceHttpService.postItem(
                this.company,
                this.companyPlace
            );
            if (!postResult) {
                return false;
            } else {
                this.companyPlaces.data.push(postResult.data);
                return true;
            }
        }
    }

    async deletePlace(id) {
        const deleteResult = await this.companyPlaceHttpService.deleteItem(
            this.company,
            id
        );

        if (!deleteResult) {
            return false;
        } else {
            const index = this.companyPlaces.data.findIndex((obj) => obj.id === id);
            this.companyPlaces.data.splice(index, 1);
            return true;
        }
    }

    /**************************************************************************
     * Trips
     */

    getTrips = async (query: string = '') =>
        this.companyTripHttpService
            .getItems(this.company, query)
            .then((requestResult) => {
                if (requestResult.status === 200) {
                    if (requestResult.data.message) {
                        return {message: requestResult.data.message};
                    } else {
                        this.companyTrips = requestResult.data;
                        return requestResult;
                    }
                } else {
                    return false;
                }
            })

    formatTripsDataForExcel = (data: Array<TripInterface>, columns: any) => {
        const returnedData = [];
        data.map((course) => {
            const row = {};
            Object.keys(columns).map((c) => {
                // simple column
                const cExplode = c.split('__');
                if (cExplode.length == 1) {
                    if (c === 'vehicle_type') {
                        row[columns[c]] = vehicule_types[course[c]];
                    } else if (c === 'start_address' || c === 'dest_address') {
                        const address: Tripaddress = course[c];
                        row[
                            columns[c]
                            ] = `${address.number} ${address.address} ${address.city}`;
                    } else if (c === 'date_time') {
                        const date: moment.Moment = moment(new Date(course[c]));
                        row[columns[c]] = date.format('DD/MM/YYYY').toString();
                        row['Heure de prise en charge'] = date.format('HH:mm').toString();
                    } else {
                        row[columns[c]] = course[c] || '';
                    }
                } else {
                    const obj = cExplode[0];
                    const field = cExplode[1];
                    if (course[obj] && course[obj][field]) {
                        row[columns[c]] = course[obj][field];
                    } else {
                        row[columns[c]] = '';
                    }
                }
            });
            returnedData.push(row);
        });
        return returnedData;
    }

    /**************************************************************************
     * Statistiques views
     */

    getViews(): Observable<any> {
        return this.companiesHttpService.getViews(this.company);
    }

    getViewData = (viewName: string): Observable<any> => {
        return this.companiesHttpService.getViewData(this.company, viewName);
    }

    getLastThreeMonthCourses(companyId: string, query = '') {
        return  this.companiesHttpService.getLastThreeMonthCourses(companyId, query).toPromise();
    }
}
