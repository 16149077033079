import {Injectable} from '@angular/core';
import {Userdata, UserInterface} from '../../interfaces/user-interface';
import {Router} from '@angular/router';
import {UserHttpServiceService} from '../api/user-http-service.service';
import {CompanyService} from "./company.service";
import { Storage } from '@capacitor/storage';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    userdata: UserInterface;
    localeConfig: any;

    constructor(public userHttpService: UserHttpServiceService,
                private companyService: CompanyService) {
    }

    async init() {
        const localeData = await Storage.get({key: 'atnadministrator.user'});
        const localeConfigJson = await Storage.get({key: 'atnadministrator.config'});

        if (localeData.value) {
            this.userdata = JSON.parse(localeData.value);
            const isConnected = await this.getMe();
            if (isConnected) {
                await this.setHomepage();
            }
            else {
                this.userdata = {token_expire: 0};
                this.userdata.homePage = 'login';
            }
        } else {
            this.userdata = {token_expire: 0};
            this.userdata.homePage = 'login';
        }

        if (localeConfigJson.value) {
            this.localeConfig = await JSON.parse(localeConfigJson.value);
        } else {
            this.localeConfig = {
                splitmenu: true,
                splitmenusize: 1200
            };
        }

        console.log('loc ', this.localeConfig);
    }

    async saveUser() {
        await Storage.set({
            key: 'atnadministrator.user',
            value: JSON.stringify(this.userdata)
        });
    }

    async saveLocaleConfig() {
        await Storage.set({
            key: 'atnadministrator.config',
            value: JSON.stringify(this.localeConfig)
        });
    }

    isLoggedIn() {
        const current = new Date();
        const timestamp = current.getTime();

        return !(!this.userdata || this.userdata.token_expire < timestamp);
    }

    async login(credential: any) {

        const loginResult = await this.userHttpService.login(credential);

        if (loginResult.status === 200) {
            this.userdata.token = loginResult.data;
            const current = new Date();
            this.userdata.token_expire = current.getTime() + (this.userdata.token.expires_in * 1000);
            const me = await this.userHttpService.getMe(this.userdata.token.access_token);
            this.userdata.userdata = me.data;
            this.saveUser();
            await this.setHomepage();
            return true;
        }

        return false;
    }

    async logout() {
        const loginResult = await this.userHttpService.logout(this.getToken());

        if (loginResult.status === 200) {
            this.userdata = {token_expire: 0};
            await Storage.remove({key: 'atnadministrator.user'});
            return true;
        }

        return false;
    }

    hasRole(role: string) {
        if (!this.userdata || !this.userdata.userdata) {
            return false;
        } else {
            return this.userdata.userdata.roles.indexOf(role) > -1;
        }

    }

    getToken() {
        if (this.isLoggedIn()) {
            return this.userdata.token.access_token;
        }
        return '';
    }

    async getMe() {
        const me = await this.userHttpService.getMe(this.userdata.token.access_token);
        if (me.status !== 200) {
            return false;
        }
        this.userdata.userdata = me.data;
        this.saveUser();
        return true;
    }

    async setHomepage() {
        if (await this.hasRole('super admin')) {
            this.userdata.homePage = '/admin/dashboard';
        } else {
            const ok = await this.companyService.getItem(this.userdata.userdata.company_id);
            this.userdata.homePage = '/company/' + this.userdata.userdata.company_id + '/dashboard';
        }
    }
}
