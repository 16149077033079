import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { CompanyDriverInterface, CompanyDriversInterface, CompanyInterface } from "../../interfaces/companies-interface";
import { CompanyVehiclesInterface, VehicleInterface } from "../../interfaces/company-vehicles-interface";
import { CompanyUsersInterface } from "../../interfaces/company-users-interface";
import { Userdata } from "../../interfaces/user-interface";

@Injectable({
    providedIn: 'root'
})
export class CompanyDriverHttpService {

    apiUrl = environment.dataApiDriver;

    constructor(private httpClient: HttpClient) {

    }

    async getItems(company: CompanyInterface) {
        const url = this.apiUrl + 'admin/liste-taxi/' + company.id//+ company.id + '/users';

        return await this.httpClient
            .get<CompanyDriversInterface>(url)
            .toPromise()
            .then(data => {
                return { status: 200, data };
            })
            .catch(error => {
                return error;
            });
    }

    getItemsHttp(companyId: string) {
        const url = this.apiUrl + 'admin/liste-taxi/' + companyId;
        return this.httpClient
            .get<CompanyDriversInterface>(url);
    }
}
