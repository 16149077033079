import {DriverData} from './../../interfaces/user-interface';
import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {CompanyInterface} from "../../interfaces/companies-interface";
import {CompanyVehiclesInterface, VehicleInterface} from "../../interfaces/company-vehicles-interface";
import {CompanyUsersInterface} from "../../interfaces/company-users-interface";
import {Userdata} from "../../interfaces/user-interface";

@Injectable({
    providedIn: 'root'
})
export class CompanyUserHttpService {
    apiUrl = environment.dataApi + 'companies/';

    constructor(private httpClient: HttpClient) {
    }

    async getItems(company: CompanyInterface) {
        const url = this.apiUrl + company.id + '/users';

        return await this.httpClient
            .get<CompanyUsersInterface>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });
    }

    async getItem(company: CompanyInterface, userId) {
        const url = this.apiUrl + company.id + '/users/' + userId;

        return await this.httpClient
            .get<Userdata>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });
    }

    async postItem(company: CompanyInterface, body: Userdata) {
        const url = this.apiUrl + company.id + '/users';

        return await this.httpClient
            .post<Userdata>(url, body)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    async putItem(company: CompanyInterface, userId: string, body: Userdata) {
        const url = this.apiUrl + company.id + '/users/' + userId;

        return await this.httpClient
            .put<Userdata>(url, body)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    async deleteItem(company: CompanyInterface, userId) {
        const url = this.apiUrl + company.id + '/users/' + userId;

        return await this.httpClient
            .delete<any>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    /***
     * Drivers User Accont
     */
    async getDriverAccounItem(company: CompanyInterface, driverId) {
        const url = this.apiUrl + company.id + '/drivers/' + driverId;

        return await this.httpClient
            .get<DriverData>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });
    }

    async postDriverAccountItem(companyUserDriver: DriverData) {
        const url = this.apiUrl + companyUserDriver.company_id + '/drivers';

        return await this.httpClient
            .post<DriverData>(url, companyUserDriver)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    async putDriverAccountItem(company: CompanyInterface, driverId: number, body: DriverData) {
        const url = this.apiUrl + company.id + '/drivers/' + driverId;

        return await this.httpClient
            .put<DriverData>(url, body)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    async deleteDriverAccountItem(company: CompanyInterface, userId) {
        const url = this.apiUrl + company.id + '/drivers/' + userId;

        return await this.httpClient
            .delete<any>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }
}
