import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CompaniesInterface, CompanyInterface} from '../../interfaces/companies-interface';
import {CompanyVehiclesInterface, VehicleInterface} from '../../interfaces/company-vehicles-interface';

@Injectable({
    providedIn: 'root'
})
export class CompanyVehicleHttpService {
    apiUrl = environment.dataApi + 'companies/';

    constructor(private httpClient: HttpClient) {
    }

    async getItems(company: CompanyInterface) {
        const url = this.apiUrl + company.id + '/vehicles';

        return await this.httpClient
            .get<CompanyVehiclesInterface>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });
    }

    async getItem(company: CompanyInterface, vehicleId) {
        const url = this.apiUrl + company.id + '/vehicles/' + vehicleId;

        return await this.httpClient
            .get<VehicleInterface>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    async postItem(company: CompanyInterface, body: VehicleInterface) {
        const url = this.apiUrl + company.id + '/vehicles';

        return await this.httpClient
            .post<VehicleInterface>(url, body)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }
    async putItem(company: CompanyInterface, vehicleId: string, body: VehicleInterface) {
        const url = this.apiUrl + company.id + '/vehicles/' + vehicleId;

        return await this.httpClient
            .put<VehicleInterface>(url, body)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    async deleteItem(company: CompanyInterface, vehicleId) {
        const url = this.apiUrl + company.id + '/vehicles/' + vehicleId;

        return await this.httpClient
            .delete<any>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }
}
