import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CompanyInterface} from '../../interfaces/companies-interface';
import {CompanyRelaysInterface, RelayInterface} from '../../interfaces/company-relays-interface';
import {CompanyPlaceInterface, CompanyPlacesInterface} from '../../interfaces/company-place-interface';

@Injectable({
    providedIn: 'root'
})
export class CompanyPlaceHttpService {
    apiUrl = environment.dataApi + 'companies/';

    constructor(private httpClient: HttpClient) {
    }

    async getItems(company: CompanyInterface) {
        const url = this.apiUrl + company.id + '/places';

        return await this.httpClient
            .get<CompanyPlacesInterface>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });
    }

    async getItem(company: CompanyInterface, placeId) {
        const url = this.apiUrl + company.id + '/places/' + placeId;

        return await this.httpClient
            .get<CompanyPlaceInterface>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    async postItem(company: CompanyInterface, body: CompanyPlaceInterface) {
        const url = this.apiUrl + company.id + '/places';

        return await this.httpClient
            .post<CompanyPlaceInterface>(url, body)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    async putItem(company: CompanyInterface, placeId: string, body: CompanyPlaceInterface) {
        const url = this.apiUrl + company.id + '/places/' + placeId;

        return await this.httpClient
            .put<CompanyPlaceInterface>(url, body)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    async deleteItem(company: CompanyInterface, placeId) {
        const url = this.apiUrl + company.id + '/places/' + placeId;

        return await this.httpClient
            .delete<any>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

}
