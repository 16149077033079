import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CompaniesInterface, CompanyInterface} from '../../interfaces/companies-interface';
import {CompanyVehiclesInterface, VehicleInterface} from '../../interfaces/company-vehicles-interface';
import {CompanyRelaysInterface, RelayInterface} from "../../interfaces/company-relays-interface";

@Injectable({
    providedIn: 'root'
})
export class CompanyRelayHttpService {
    apiUrl = environment.dataApi + 'companies/';

    constructor(private httpClient: HttpClient) {
    }

    async getItems(company: CompanyInterface) {
        const url = this.apiUrl + company.id + '/relays';

        return await this.httpClient
            .get<CompanyRelaysInterface>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });
    }

    async getItem(company: CompanyInterface, relayId) {
        const url = this.apiUrl + company.id + '/relays/' + relayId;

        return await this.httpClient
            .get<RelayInterface>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }

    async postItem(company: CompanyInterface, body: RelayInterface) {
        const url = this.apiUrl + company.id + '/relays';

        return await this.httpClient
            .post<RelayInterface>(url, body)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }
    async putItem(company: CompanyInterface, relayId: string, body: RelayInterface) {
        const url = this.apiUrl + company.id + '/relays/' + relayId;

        return await this.httpClient
            .put<RelayInterface>(url, body)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }
    async deleteItem(company: CompanyInterface, relayId) {
        const url = this.apiUrl + company.id + '/relays/' + relayId;

        return await this.httpClient
            .delete<any>(url)
            .toPromise()
            .then(data => {
                return {status: 200, data};
            })
            .catch(error => {
                return error;
            });

    }
}
