import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    ADSDocumentInterface,
    DriverLicenseInterface,
    EntrepriseDocumentInterface,
    InsuranceDocumentInterface,
    ProfessionalLicenseDocumentInterface,
    VehiculeControlDocument,
    VehiculeDocument,
    RcProDocument,
    FormationContinueDocument,
    LicenseTransportDocument,
    LicenseMarchandiseDocument,
    VisiteMedicaleDocument,
    CompanyDriverFolderInterface,
} from 'src/app/interfaces/companies-interface';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CompanyDriverFolderHttpService {
    apiUrl = environment.dataApiDriver;

    constructor(private httpClient: HttpClient) {
    }

    async getRequiredDocuments(companyId: string) {
        const url = `${this.apiUrl}companies/${companyId}/required_documents`;

        return await this.httpClient
            .get<any>(url)
            .toPromise()
            .then((data) => ({status: 200, data}))
            .catch((error) => error);
    }

    async changeCompany(id: string, userId) {
        return await this.httpClient
            .post<any>(
                this.apiUrl + 'companies/' + id + '/users/' + userId + '/movetocompany', null
            )
            .toPromise()
            .then(
                (data) => ({status: 200, data}))
            .catch((error) => error);
    }

    async deleteFolder(id) {
        return await this.httpClient
            .delete<any>(
                this.apiUrl + 'users/' + id + '/delete'
            )
            .toPromise()
            .then(
                (data) => ({status: 200, data}))
            .catch((error) => error);
    }


    async getFolder(id: string) {
        return await this.httpClient
            .get<CompanyDriverFolderInterface>(
                this.apiUrl + 'users/' + id + '/folder'
            )
            .toPromise()
            .then(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log({err});
                }
            );
    }

    async getADS(id: string) {
        return await this.httpClient
            .get<ADSDocumentInterface>(this.apiUrl + 'users/' + id + '/ads')
            .toPromise()
            .then(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log({err});
                }
            );
    }

    async getDriverLicenseDocument(id: string) {
        return await this.httpClient
            .get<DriverLicenseInterface>(
                this.apiUrl + 'users/' + id + '/driverlicense'
            )
            .toPromise()
            .then(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log({err});
                }
            );
    }

    async getEntrepriseDocument(id: string) {
        return await this.httpClient
            .get<EntrepriseDocumentInterface>(
                this.apiUrl + 'users/' + id + '/entreprise'
            )
            .toPromise()
            .then(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log({err});
                }
            );
    }

    async getInsuranceDocument(id: string) {
        return await this.httpClient
            .get<InsuranceDocumentInterface>(
                this.apiUrl + 'users/' + id + '/insurance'
            )
            .toPromise()
            .then(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log({err});
                }
            );
    }

    async getProfessionalLicenseDocument(id: string) {
        return await this.httpClient
            .get<ProfessionalLicenseDocumentInterface>(
                this.apiUrl + 'users/' + id + '/profesionallicence'
            )
            .toPromise()
            .then(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log({err});
                }
            );
    }

    async getVehicule(id: string) {
        return await this.httpClient
            .get<VehiculeDocument>(this.apiUrl + 'users/' + id + '/vehicle')
            .toPromise()
            .then(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log({err});
                }
            );
    }

    async getVehiculeControle(id: string) {
        return await this.httpClient
            .get<VehiculeControlDocument>(
                this.apiUrl + 'users/' + id + '/vehiclecontrol'
            )
            .toPromise()
            .then(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log({err});
                }
            );
    }

    async getVisiteMedical(id: string) {
        return await this.httpClient
            .get<VisiteMedicaleDocument>(
                this.apiUrl + 'users/' + id + '/visitemedicale'
            )
            .toPromise()
            .then(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log({err});
                }
            );
    }

    async getRcPro(id: string) {
        return await this.httpClient
            .get<RcProDocument>(this.apiUrl + 'users/' + id + '/rcpro')
            .toPromise()
            .then(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log({err});
                }
            );
    }

    async getFormationContinue(id: string) {
        return await this.httpClient
            .get<FormationContinueDocument>(
                this.apiUrl + 'users/' + id + '/formationcontinue'
            )
            .toPromise()
            .then(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log({err});
                }
            );
    }

    async getLicenseTransport(id: string) {
        return await this.httpClient
            .get<LicenseTransportDocument>(
                this.apiUrl + 'users/' + id + '/licensetransport'
            )
            .toPromise()
            .then(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log({err});
                }
            );
    }

    async getLicenseMarchandise(id: string) {
        return await this.httpClient
            .get<LicenseMarchandiseDocument>(
                this.apiUrl + 'users/' + id + '/licensemarchandise'
            )
            .toPromise()
            .then(
                (data) => {
                    return data;
                },
                (err) => {
                    console.log({err});
                }
            );
    }

    async postADS(id: string, data) {
        return await this.httpClient
            .post<ADSDocumentInterface>(this.apiUrl + 'users/' + id + '/ads', data)
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async postDriverLicenseDocument(id: string, data) {
        return await this.httpClient
            .post<DriverLicenseInterface>(
                this.apiUrl + 'users/' + id + '/driverlicense', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async postEntrepriseDocument(id: string, data) {
        return await this.httpClient
            .post<EntrepriseDocumentInterface>(
                this.apiUrl + 'users/' + id + '/entreprise', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async postInsuranceDocument(id: string, data) {
        return await this.httpClient
            .post<InsuranceDocumentInterface>(
                this.apiUrl + 'users/' + id + '/insurance', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async postProfessionalLicenseDocument(id: string, data) {
        return await this.httpClient
            .post<ProfessionalLicenseDocumentInterface>(
                this.apiUrl + 'users/' + id + '/profesionallicence', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async postVehicule(id: string, data) {
        return await this.httpClient
            .post<VehiculeDocument>(this.apiUrl + 'users/' + id + '/vehicle', data)
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async postVehiculeControle(id: string, data) {
        return await this.httpClient
            .post<VehiculeControlDocument>(
                this.apiUrl + 'users/' + id + '/vehiclecontrol', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async postVisiteMedical(id: string, data) {
        return await this.httpClient
            .post<VisiteMedicaleDocument>(
                this.apiUrl + 'users/' + id + '/visitemedicale', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async postRcPro(id: string, data) {
        return await this.httpClient
            .post<RcProDocument>(this.apiUrl + 'users/' + id + '/rcpro', data)
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async postFormationContinue(id: string, data) {
        return await this.httpClient
            .post<FormationContinueDocument>(
                this.apiUrl + 'users/' + id + '/formationcontinue', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async postLicenseTransport(id: string, data) {
        return await this.httpClient
            .post<LicenseTransportDocument>(
                this.apiUrl + 'users/' + id + '/licensetransport', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async postLicenseMarchandise(id: string, data) {
        return await this.httpClient
            .post<LicenseMarchandiseDocument>(
                this.apiUrl + 'users/' + id + '/licensemarchandise', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }


    async updateADS(id: string, data) {
        return await this.httpClient
            .put<ADSDocumentInterface>(this.apiUrl + 'users/' + id + '/ads', data)
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async updateDriverLicenseDocument(id: string, data) {
        return await this.httpClient
            .put<DriverLicenseInterface>(
                this.apiUrl + 'users/' + id + '/driverlicense', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async updateEntrepriseDocument(id: string, data) {
        return await this.httpClient
            .put<EntrepriseDocumentInterface>(
                this.apiUrl + 'users/' + id + '/entreprise', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async updateInsuranceDocument(id: string, data) {
        return await this.httpClient
            .put<InsuranceDocumentInterface>(
                this.apiUrl + 'users/' + id + '/insurance', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async updateProfessionalLicenseDocument(id: string, data) {
        return await this.httpClient
            .put<ProfessionalLicenseDocumentInterface>(
                this.apiUrl + 'users/' + id + '/profesionallicence', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async updateVehicule(id: string, data) {
        return await this.httpClient
            .put<VehiculeDocument>(this.apiUrl + 'users/' + id + '/vehicle', data)
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async updateVehiculeControle(id: string, data) {
        return await this.httpClient
            .put<VehiculeControlDocument>(
                this.apiUrl + 'users/' + id + '/vehiclecontrol', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async updateVisiteMedical(id: string, data) {
        return await this.httpClient
            .put<VisiteMedicaleDocument>(
                this.apiUrl + 'users/' + id + '/visitemedicale', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async updateRcPro(id: string, data) {
        return await this.httpClient
            .put<RcProDocument>(this.apiUrl + 'users/' + id + '/rcpro', data)
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async updateFormationContinue(id: string, data) {
        return await this.httpClient
            .put<FormationContinueDocument>(
                this.apiUrl + 'users/' + id + '/formationcontinue', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async updateLicenseTransport(id: string, data) {
        return await this.httpClient
            .put<LicenseTransportDocument>(
                this.apiUrl + 'users/' + id + '/licensetransport', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    async updateLicenseMarchandise(id: string, data) {
        return await this.httpClient
            .put<LicenseMarchandiseDocument>(
                this.apiUrl + 'users/' + id + '/licensemarchandise', data
            )
            .toPromise()
            .then((data) => ({data, status: 200}))
            .catch(error => ({status: error.status, error}));
    }

    streamImage = (url: string) => {
        return this.httpClient.get(url, {
            headers: {
                Accept: 'image/webp,image/apng,image/svg+xml,image/*,*/*', 'Content-Type': 'image/png'
            },
            responseType: 'blob'
        })
            .toPromise()
            .then(data => ({status: 200, data}))
            .catch(error => error);
    }


    async updateFolderState(folderId: string, data) {
        return await this.httpClient.post(`${this.apiUrl}admin/update-folder-state/${folderId}`, data).toPromise();
    }

    async updateInternalCode(userId: number, data) {
        return await this.httpClient.post(`${this.apiUrl}admin/update-internal-code/${userId}`, data).toPromise();
    }


}
