import {NgModule} from "@angular/core";
import {PreloadAllModules, RouterModule, Routes} from "@angular/router";
import {AuthGuardService} from "./services/auth-guard.service";

const routes: Routes = [
    {
        path: "login",
        loadChildren: () =>
            import("./pages/login/login.module").then((m) => m.LoginPageModule),
    },
    {
        path: "admin/dashboard",
        loadChildren: () =>
            import("./pages/superadmin/companies/companies.module").then(
                (m) => m.CompaniesPageModule
            ),
        canActivate: [AuthGuardService],
        data: {roles: ["super admin"]},
    },
    {
        path: "company/:id/dashboard",
        loadChildren: () =>
            import("./pages/company/company/company.module").then(
                (m) => m.CompanyPageModule
            ),
        canActivate: [AuthGuardService],
        data: {roles: ["super admin", "admin"]},
    },
    {
        path: "company/:id/driver/:driverId",
        loadChildren: () =>
            import("./pages/company/driver-folder/driver-folder.module").then(
                (m) => m.DriverFolderPageModule
            ),
        canActivate: [AuthGuardService],
        data: {roles: ["super admin"]},
    },
    {
        path: "company/:id/places",
        loadChildren: () =>
            import("./pages/company/place-list/place-list.module").then(
                (m) => m.PlaceListPageModule
            ),
        canActivate: [AuthGuardService],
        data: {roles: ["super admin", "admin"]},
    },
    {
        path: "company/:id/vehicles",
        loadChildren: () =>
            import("./pages/company/taxi-list/taxi-list.module").then(
                (m) => m.TaxiListPageModule
            ),
        canActivate: [AuthGuardService],
        data: {roles: ["super admin", "admin"]},
    },
    {
        path: "company/:id/driver",
        loadChildren: () => import('./pages/company/driver-ag-list/driver-ag-list.module').then(m => m.DriverAgListPageModule),
        canActivate: [AuthGuardService],
        data: {roles: ["super admin", "admin"]},
    },
    {
        path: "company/:id/relays",
        loadChildren: () =>
            import("./pages/company/relay-list/relay-list.module").then(
                (m) => m.RelayListPageModule
            ),
        canActivate: [AuthGuardService],
        // data: {roles: ['super admin', 'admin&&company_id']}
        data: {roles: ["super admin", "admin"]},
    },
    {
        path: "company/:id/users",
        loadChildren: () =>
            import("./pages/company/user-list/user-list.module").then(
                (m) => m.UserListPageModule
            ),
        canActivate: [AuthGuardService],
        data: {roles: ["super admin", "admin"]},
        // data: {roles: ['super admin', 'admin&&company_id']}
    },
    {
        path: "company/:id/taxi-edit",
        loadChildren: () =>
            import("./pages/company/taxi-edit/taxi-edit.module").then(
                (m) => m.TaxiEditPageModule
            ),
        canActivate: [AuthGuardService],
        data: {roles: ["super admin", "admin"]},
    },
    {
        path: "company/:id/user-edit",
        loadChildren: () =>
            import("./pages/company/user-edit/user-edit.module").then(
                (m) => m.UserEditPageModule
            ),
        canActivate: [AuthGuardService],
        data: {roles: ["super admin", "admin"]},
    },
    {
        path: "company/:id/relays/:relayId",
        loadChildren: () =>
            import("./pages/company/relay-edit-form/relay-edit-form.module").then(
                (m) => m.RelayEditFormPageModule
            ),
        canActivate: [AuthGuardService],
        data: {roles: ["super admin", "admin"]},
    },
    {
        path: "company/:id/place-edit",
        loadChildren: () =>
            import("./pages/company/place-edit/place-edit.module").then(
                (m) => m.PlaceEditPageModule
            ),
        canActivate: [AuthGuardService],
        data: {roles: ["super admin", "admin"]},
    },
    {
        path: "company/:id/export",
        loadChildren: () =>
            import("./pages/company/export/export.module").then(
                (m) => m.ExportPageModule
            ),
        canActivate: [AuthGuardService],
        data: {roles: ["super admin", "admin"]},
    },
    {
        path: "export",
        loadChildren: () =>
            import("./pages/company/export/export.module").then(
                (m) => m.ExportPageModule
            ),
    },
    {
        path: "company/:id/courses",
        loadChildren: () =>
            import("./pages/company/course/course.module").then(
                (m) => m.CoursePageModule
            ),
    },
    {
        path: "company/:id/app-users",
        loadChildren: () =>
            import("./pages/company/users/users.module").then(
                (m) => m.UsersPageModule
            ),
    },
    {
        path: 'company-list-modal',
        loadChildren: () => import('./pages/company/company-list-modal/company-list-modal.module').then(m => m.CompanyListModalPageModule)
    },


];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
