import {Component, OnInit} from '@angular/core';

import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {UserService} from './services/dataobject/user.service';
import {Router} from '@angular/router';
import {CompanyService} from './services/dataobject/company.service';
import { Device } from '@capacitor/device';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    public selectedIndex = 0;
    public infos = null;
    public state = 'windows';
    loaded = false;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public userService: UserService,
        public companyService: CompanyService,
        private router: Router
    ) {
        this.initializeApp();
    }

    async initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }
    async ngOnInit() {
        await this.userService.init();
        this.router.navigate([this.userService.userdata.homePage]);
        this.infos = await Device.getInfo();
        console.log(this.infos);
        if(this.infos.operatingSystem==='ios' || this.infos.operatingSystem==='android') {
            this.userService.localeConfig.splitmenusize = 2500;
            this.userService.localeConfig.splitmenu = false;
            this.state = 'other';
            console.log(this.userService.localeConfig);
        }
        this.loaded = true;
    }

    async logout() {
        const logoutResult = await this.userService.logout();
        this.router.navigate(['/login']);
    }

    changeMenuDisplay($event) {
        if ($event.detail.checked) {
            this.userService.localeConfig.splitmenusize = 1024;
            this.userService.localeConfig.splitmenu = true;
        } else {
            this.userService.localeConfig.splitmenusize = 2500;
            this.userService.localeConfig.splitmenu = false;
        }
        this.userService.saveLocaleConfig();
    }
}
